var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[{ label: 'Estructura Salarial Interna', to: '/estructura-salarial-interna' }],"currentView":{ label: 'Empleados' }}}),_c('h2',[_vm._v("Empleados: "+_vm._s(_vm.position.name))])],1),_c('div',{staticClass:"content__buttons"},[_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"}},[_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16px","width":"16px"}})],1)]},proxy:true},{key:"options",fn:function(){return [_c('menu-item',{on:{"click":_vm.downloadExcel}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"var(--font-color-700)"}}),_vm._v(" Descargar Excel ")],1)]},proxy:true}])})],1)]),_c('div',{staticClass:"filter__container"},[_c('Menu',{attrs:{"direction":"below","closeOnItemClick":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('filter-item',{attrs:{"filter":_vm.incomeFilter}})]},proxy:true},{key:"options",fn:function(){return _vm._l(([].concat( _vm.incomeFilter.options )),function(option){return _c('menu-item',{key:option.id,on:{"click":function($event){option.active = !option.active;
            _vm.filterEmployees();}}},[_vm._v(" "+_vm._s(option.name)+" "),(option.active)?_c('unicon',{attrs:{"width":"16px","height":"16px","name":"check","fill":""}}):_vm._e()],1)})},proxy:true}])}),_c('Filters',{ref:"filters",attrs:{"filters":_vm.categories,"filtersOptions":_vm.options},on:{"filter":function (activeFilters) {
          _vm.filters = activeFilters;
          _vm.filterEmployees(activeFilters);
        }}})],1),_c('div',{staticClass:"content"},[_c('table',{ref:"table"},[_c('tr',[_c('th',{attrs:{"rowspan":"1"}},[_vm._v("#")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Nombre")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Nivel laboral")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Fecha de ingreso")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Género")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Mediana")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Sueldo")]),_c('th',{attrs:{"rowspan":"1"}},[_c('div',{style:({
              display: 'flex',
              'flex-flow': 'column',
            })},[_c('span',[_vm._v("Estructura Salarial")]),_c('span',[_vm._v("(Min - Max)")])])]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Status Estructura Salarial")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("Salario vs Mediana")]),_c('th',{attrs:{"colspan":"1"}},[_c('div',{style:({
              display: 'flex',
              'flex-flow': 'column',
            })},[_c('span',[_vm._v(" Salario vs Porcentaje")]),_c('span',[_vm._v("cercano a la Mediana")])])])]),_vm._l((_vm.filteredEmployees),function(employee,index){return _c('tr',{key:employee.id,attrs:{"set":(_vm.value =
            employee.status === 'over'
              ? _vm.company.salaryEquityCongif.max / 100
              : employee.status === 'below'
              ? _vm.company.salaryEquityCongif.min / 100
              : 1)}},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(employee.name))]),_c('td',[_vm._v(_vm._s(_vm.options.find(function (ref) {
                      var id = ref.id;

                      return id === employee.nivellaboral;
            }).name))]),_c('td',[_vm._v(_vm._s(_vm.timeStampToDMY(employee.entryDate)))]),_c('td',[_vm._v(_vm._s(employee.gender))]),_c('td',[_vm._v(_vm._s(_vm.meanAndMedians.medians.toFixed(2)))]),_c('td',[_vm._v(_vm._s(employee.salary.toFixed(2)))]),_c('td',[_c('span',{staticClass:"status__cell",class:"status__cell--yellow"},[_vm._v(_vm._s(("[" + (_vm.position.payEquity && _vm.position.payEquity.min && _vm.position.payEquity.min.isActive ? _vm.position.payEquity.min.value.toFixed(0) : ((_vm.meanAndMedians.medians * _vm.company.salaryEquityCongif.min) / 100 || 0).toFixed( 0 )) + " - " + (_vm.position.payEquity && _vm.position.payEquity.max && _vm.position.payEquity.max.isActive ? _vm.position.payEquity.max.value.toFixed(0) : ((_vm.meanAndMedians.medians * _vm.company.salaryEquityCongif.max) / 100 || 0).toFixed( 0 )) + "]")))])]),_c('td',[_vm._v(" "+_vm._s(employee.status === 'below' ? 'Por debajo de estructura salarial' : employee.status === 'over' ? 'Por encima de estructura salarial' : 'Dentro de estructura salarial')+" ")]),_c('td',{staticClass:"status__flex"},[_c('span',{staticClass:"status__cell",class:("status__cell--" + (employee.status2))},[_vm._v(" "+_vm._s(( Math.abs((employee.salary - _vm.meanAndMedians.medians) / _vm.meanAndMedians.medians) * 100 ).toFixed(2))+"% ")]),_c('span',{attrs:{"title":employee.status2 === 'below'
                ? 'por debajo de la mediana'
                : employee.status2 === 'over'
                ? 'por encima de la mediana'
                : 'dentro de la mediana'}},[_c('unicon',{attrs:{"name":employee.status2 === 'below'
                  ? 'arrow-down'
                  : employee.status2 === 'over'
                  ? 'arrow-up'
                  : 'check',"fill":"currentColor","width":"16px","height":"16px"}})],1)]),_c('td',[_c('span',{staticClass:"status__cell",class:("status__cell--" + (employee.status3))},[_vm._v(" "+_vm._s(( Math.abs((employee.salary - _vm.meanAndMedians.medians) / _vm.meanAndMedians.medians) * 100 ).toFixed(2))+"% ")])])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }